import { HttpClient, HttpContext } from '@angular/common/http';
import { DestroyRef, Injectable, WritableSignal, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import {BYPASS_AUTH, IS_PUBLIC} from "../interceptor/authentication.interceptor";

import {JwtHelperService} from "@auth0/angular-jwt";
import { LoggedUser, LoginRequest, LoginResponse, LoginResponseSuccess, Role, User } from '../models/user.model';
import { Preferences } from '@capacitor/preferences';
import { Observable, catchError, lastValueFrom, of, tap } from 'rxjs';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { Dialog } from '@capacitor/dialog';

import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly jwtHelper = inject(JwtHelperService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly CONTEXT = {context: new HttpContext().set(IS_PUBLIC, true)};
  private readonly CONTEXT_REFRESH = {context: new HttpContext().set(BYPASS_AUTH, true)};
  private readonly TOKEN_EXPIRY_THRESHOLD_MINUTES = 5;
  isLoggedIn: boolean = false;
  menuChanged = signal<boolean>(false);
  userRole = signal<string>('');

  constructor() { }

  changeMenu(change: boolean):void {
    this.menuChanged.set(change);
  }
  setUserRole(role: string): void {
    this.userRole.set(role);
  }
 
  async getUserRole() {
    const token = await this.getToken();
    if(!token) return null;
    return this.jwtHelper.decodeToken(token).role as string;
  }

  async getUser() {
    const token = await this.getToken();
    return signal(token ? this.jwtHelper.decodeToken(token) : null);
  }

  async isAuthenticated(): Promise<boolean> {
    const token = await this.getToken();
    if(token) return !this.jwtHelper.isTokenExpired(token);
    else return false;
  }

  async storeTokens(response: LoginResponseSuccess) {
    if(response && response.data) {
      await Preferences.set({key: 'token', value: response.data.token});
      await Preferences.set({key: 'refreshToken', value: response.data.refreshToken});
    }
  }

  loginSsoToken(ssoToken: string): Observable<LoginResponse>  {
    return this.http.post<LoginResponse>(`${env.apiUrl}/auth/exchange`, {ssoToken: ssoToken}, this.CONTEXT)
    .pipe(
      catchError(error => {
        console.log('error ', error);
        this.dialogMessage('Attenzione!', error.error.message)
        return of();
      }),
      tap(response => {
        const loginSuccessData = response as unknown as LoginResponseSuccess;
        if (loginSuccessData && loginSuccessData.data && loginSuccessData.data.token) {
          this.isLoggedIn = true;
          this.storeTokens(loginSuccessData);
          this.scheduleTokenRefresh(loginSuccessData.data.token);
          this.storeId();
          this.changeMenu(true);
          this.getUserRole();
          this.router.navigate(['/conad/welcome']);
        }
      })
    )
  }

  login(loginData: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${env.apiUrl}/auth/login`, loginData, this.CONTEXT)
      .pipe(
        catchError(error => {
          console.log('error ', error);
          this.dialogMessage('Attenzione!', error.error.message)
          return of();
        }),
        tap(async data => {
          const loginSuccessData = data as unknown as LoginResponseSuccess;
          if (loginSuccessData && loginSuccessData.data && loginSuccessData.data.token) {
            this.isLoggedIn = true;
            this.storeTokens(loginSuccessData);
            this.scheduleTokenRefresh(loginSuccessData.data.token);
            this.storeId();
            this.changeMenu(true);
            const role = await this.getUserRole();
            if(role && role == Role.ASD) {
              this.setUserRole(Role.ASD)
              this.router.navigate(['/asd/carica-buoni-sport']);
            } else if(role === Role.CONAD) {
              this.setUserRole(Role.CONAD);
              this.router.navigate(['/conad/welcome']);
            } else if(role === Role.BACKOFFICE) {
              this.setUserRole(Role.BACKOFFICE);
              this.router.navigate(['/back-office/elenco-asd']);
            }
          }
        })
      );
  }

  async logout() {
    await Preferences.remove({key: 'token'});
    await Preferences.remove({key: 'refreshToken'}); 
    await Preferences.remove({key: 'asdId'}); 
    await Preferences.remove({key: 'conadId'}); 
    await Preferences.remove({key: 'backofficeId'}); 
    this.isLoggedIn = false;
    this.changeMenu(false)
    this.router.navigate(['/home']);
  }

  async dialogMessage(title: string, message: string) {
    await Dialog.alert({
      title: title,
      message: message
    })
  }

  async storeId(): Promise<void> {
    let loggedUser = signal<LoggedUser| null>(null);
    await this.getUser().then(loggedUserRes => {
      return loggedUser = loggedUserRes
    });
    if(loggedUser()!.role === Role.ASD) {
      await Preferences.set({key: 'asdId', value: (loggedUser()!.representativeEditionId).toString()});
    } else if(loggedUser()!.role === Role.CONAD){
      await Preferences.set({key: 'conadId', value: (loggedUser()!.representativeEditionId).toString()});
    } else if(loggedUser()!.role === Role.BACKOFFICE){
      await Preferences.set({key: 'backofficeId', value: (loggedUser()!.representativeEditionId).toString()});
    }
    
  }

  async refreshToken(): Promise<Observable<any>> {
    const refresh_token = await this.getRefreshToken();    
    if (!refresh_token) {
      return of();
    }
    const response$ =  this.http.post<LoginResponse>(
      `${env.apiUrl}/asd/refresh`, { refreshToken: refresh_token }, this.CONTEXT_REFRESH)
      .pipe(
        catchError((error) => {
          console.log('error ', error);
          return of(error)}),
        tap(data => {
          let loginSuccessData = data as unknown as LoginResponseSuccess;
          if (loginSuccessData && loginSuccessData.data && loginSuccessData.data.token) {
            this.storeTokens(loginSuccessData);
            this.scheduleTokenRefresh(loginSuccessData.data.token);
          } else {
            data = {
              message: '',
              data: {
                token: '',
                refreshToken: '',
              }
            }
          }
          return data
        })
      );
      return lastValueFrom(response$);
  }

  scheduleTokenRefresh(token: string): void {
    const expirationTime = this.jwtHelper.getTokenExpirationDate(token)?.getTime();
    const refreshTime = expirationTime ? expirationTime - this.TOKEN_EXPIRY_THRESHOLD_MINUTES * 60 * 1000 : Date.now();
    const refreshInterval = refreshTime - Date.now();

    if (refreshInterval > 0) {
      setTimeout(async () => {
        (await this.refreshToken())
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe();
      }, refreshInterval);
    }
  }
// nomi token da modificare
  async getRefreshToken() {
    const token = (await Preferences.get({key: 'refreshToken'}).then()).value;
    return token as string
  }
  async getToken() {
    const token = (await Preferences.get({key: 'token'}).then()).value;
    return token as string
  }

  resetPassword(email: string) {

  }

}
