import { ASD } from "../asd/model/asd.model";

export enum UserType {
  ASD = 1, // Associazione Sportiva Dilettantistica
}

export type User = ASD;

export interface BaseUser {
  id?: string;
  type: UserType;
  name: string;
  lastName: string;
  mail: string;
  password: string;
  mobilePhone: string;
  landline?: string;
}

export interface LoggedUser {
  representativeEditionId: string;
  username: string;
  mail: string;
  role: string;
  points: number;
  clubName: string; 
  municipality: string;
}

export interface LoginRequest {
  mail: string;
  password: string;
}
export interface LoginResponseSuccess {
  message: string;
  data: {
    token: string;
    refreshToken: string
  };
}

export enum Role {
  ASD = 'ASD',
  CONAD = 'CONAD',
  BACKOFFICE = 'BACKOFFICE'
}
export interface LoginResponseFailure {
  error: { message: string };
}
export type LoginResponse = LoginResponseSuccess | LoginResponseFailure;
