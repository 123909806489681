import { HttpContextToken, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, from, switchMap } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { JwtHelperService } from '@auth0/angular-jwt';

export const BYPASS_AUTH = new HttpContextToken<boolean>(() => false);
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private authenticationService = inject(AuthenticationService);
  private readonly jwtHelper = inject(JwtHelperService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.context.get(BYPASS_AUTH)) {
      return next.handle(request);
    }
    return from(this.getValidToken()).pipe(
      switchMap((token) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return next.handle(request);
      })
    );
  }

  private async getValidToken(): Promise<string | null> {
    await this.authenticationService.refreshToken();
    const { value: refreshedToken } = await Preferences.get({ key: 'token' });
    if (refreshedToken && !this.jwtHelper.isTokenExpired(refreshedToken)) {
      this.authenticationService.changeMenu(true);
      return refreshedToken;
    }
    return null;
  }
}

export const IS_PUBLIC = new HttpContextToken(() => false);
